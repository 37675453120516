// src/components/Footer.js

import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom';

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-content">
        <ul className="footer-links">
          <li><Link to="/">Homepage</Link></li>
          <li><Link to="/login">Login</Link></li>
          <li><Link to="/signup">Signup</Link></li>
          <li><Link to="/patients">Patients</Link></li>
          <li><Link to="/providers">Providers</Link></li>
          <li><Link to="/employers">Employers</Link></li>
          <li><Link to="/insurers">Insurers</Link></li>
        </ul>
        <p>&copy; {currentYear} SmartRep Health Inc</p>
      </div>
    </footer>
  );
}

export default Footer;
