import React from 'react';
import MyErrorBoundary from './MyErrorBoundary';
import '../App.css';

function FallbackUI() {
  return (
    <div>
      <h1>Uh oh, something went wrong!</h1>
      <p>
        We're working on fixing it, and we apologize for the inconvenience. SmartRep will be back soon.
        We hope you're having a great day!
      </p>
    </div>
  );
}

export default FallbackUI;