import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import MyErrorBoundary from './components/MyErrorBoundary';
import FallbackUI from './components/FallbackUI';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import { useNavigate, Outlet } from 'react-router-dom';
import './App.css';
  
function App() {
  const [step, setStep] = useState(1);
  const [medicarePartB, setMedicarePartB] = useState(null);
  const [selectedServices, setSelectedServices] = useState([]);

  const [showPopup, setShowPopup] = useState(false);
  const [popupText, setPopupText] = useState('');
  const navigate = useNavigate();

  const handleYesClick = () => {
    setMedicarePartB(true);
    setStep(step + 1);
  };

  const handleNoClick = () => {
    setMedicarePartB(false);
    navigate('/waitlist');
  };

  const handleLearnMoreClick = (text) => {
    setPopupText(text);
    setShowPopup(true);
  };
  
  const handleCheckboxChange = (service) => {
  const updatedServices = selectedServices.includes(service)
      ? selectedServices.filter((s) => s !== service)
      : [...selectedServices, service];

    setSelectedServices(updatedServices);
  };
  
  const handleContinueClick = () => {
    navigate('/checkout', { state: { selectedServices } });
  };

  return (
    <div className="App">
    <MyErrorBoundary>
    <Navigation />
    <Outlet />
        <div className="App-body">
        {step === 1 && (
          <div>
            <h2>
              Hi! We are here to help you stay healthy. 
            </h2>
            <p>
              Preventive care keeps people healthy, but it can be hard to know what preventive care your insurance covers.
              We want to help you find out what preventive care your insurance covers.
            </p>
            <p>Do you have coverage under Medicare Part B?</p>
            <button onClick={handleYesClick}>Yes</button>
            &nbsp;
            <button onClick={handleNoClick}>No</button>
          </div>
        )}
        {step === 2 && (
          <div>
            <p>
              Great! Just by this information, we know that you are eligible for the following preventive care.
            </p>
            <p>Select what you are interested in, and we will then continue on to determine what other preventive services you may be eligible for.</p>
            <label>
              <input
                type="checkbox"
                checked={selectedServices.includes("Annual wellness visit")}
                onChange={() => handleCheckboxChange("Annual wellness visit")}
              />
              <strong>Annual wellness visit</strong>
              &nbsp;
              |
              &nbsp;
              <em>Cost: $0</em>
              &nbsp;
              &nbsp;
              <button onClick={() => handleLearnMoreClick("Once a year, you can receive services under an annual wellness visit. This includes a personalized prevention plan of service at the initial visit.")}>
                Learn more
              </button>
            </label>
            <br />
            <label>
              <input
                type="checkbox"
                checked={selectedServices.includes("Advanced care planning")}
                onChange={() => handleCheckboxChange("Advanced care planning")}
              />
              <strong>Advanced care planning</strong>
              &nbsp;
              |
              &nbsp;
              <em>Cost: $0</em>
              &nbsp;
              &nbsp;
              <button onClick={() => handleLearnMoreClick("Advanced care planning includes an explanation and discussion of advanced directives, including completion of standard forms specific to your state. This gives you and a family member or other care partner 30 minutes with a physician or qualified health care professional to make sure there is a plan in place as you age.")}>
                Learn more
              </button>
            </label>
            <br />
            <label>
              <input
                type="checkbox"
                checked={selectedServices.includes("Annual cardiovascular disease risk reduction visit")}
                onChange={() => handleCheckboxChange("Annual cardiovascular disease risk reduction visit")}
              />
              <strong>Annual cardiovascular disease risk reduction visit</strong>
              &nbsp;
              |
              &nbsp;
              <em>Cost: $0</em>
              &nbsp;
              &nbsp;
              <button onClick={() => handleLearnMoreClick("Once a year, you can have a cardiovascular disease risk reduction visit at a primary care clinic at no cost to you. Everyone covered by Medicare Part B is eligible for this visit with no out-of-pocket cost.")}>
                Learn more
              </button>
            </label>
            <br />
            <label>
              <input
                type="checkbox"
                checked={selectedServices.includes("Heart disease screening")}
                onChange={() => handleCheckboxChange("Heart disease screening")}
              />
              <strong>Heart disease screening</strong>
              &nbsp;
              |
              &nbsp;
              <em>Cost: $0</em>
              &nbsp;
              &nbsp;
              <button onClick={() => handleLearnMoreClick("This includes blood tests for cholesterol, lipid, and triglyceride levels. These tests can detect conditions that may lead to heart disease. Every 5 years, you can have these tests done with no out-of-pocket cost to you.")}>
                Learn more
              </button>
            </label>
            <br />
            <br />
            <button onClick={handleContinueClick}>Continue</button>
          </div>
        )}
      </div>
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <p>{popupText}</p>
            <button onClick={() => setShowPopup(false)}>Close</button>
          </div>
        </div>
      )}
    <Footer />
    </MyErrorBoundary>
    </div>
  );
}

export default App;
