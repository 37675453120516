// Routes.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import App from './App';
import Checkout from './components/Checkout';
import Waitlist from './components/Waitlist';
import Patients from './components/Patients';
import Employers from './components/Employers';
import Insurers from './components/Insurers';
import Providers from './components/Providers';
import Login from './components/Login';
import Signup from './components/Signup';

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/waitlist" element={<Waitlist />} />
      <Route path="/patients" element={<Patients />} />
      <Route path="/employers" element={<Employers />} />
      <Route path="/insurers" element={<Insurers />} />
      <Route path="/providers" element={<Providers />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
    </Routes>
  );
}

export default AppRoutes;