import React from 'react';
import FallbackUI from './FallbackUI';
import '../App.css';

const logError = (error, errorInfo) => {
  // Replace this with your actual error logging logic
  console.error('Error occurred:', error);
  console.error('Error info:', errorInfo);
};

class MyErrorBoundary extends React.Component {
  state = {
    hasError: false,
  };
  
  static getDerivedStateFromError(error) {
    return {hasError: true};
  };
  componentDidCatch(error, errorInfo) {
    // A custom error logging function
    logError(error, errorInfo);
  };
  render() {
    return this.state.hasError ? <FallbackUI /> : this.props.children;
  }
}

export default MyErrorBoundary;