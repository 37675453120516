// src/components/Signup.js
import React, { useState } from 'react';
import Navigation from './Navigation';
import Footer from './Footer';
import '../App.css';

function Signup() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [interest, setInterest] = useState('');
  const [error, setError] = useState('');

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleInterestChange = (e) => {
    setInterest(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Basic validation (you can add more)
    if (!name || !email || !phone || !interest) {
      setError('Please fill in all fields.');
      return;
    }

    // You can add your signup logic here, such as sending a request to an API

    // Clear form fields
    setName('');
    setEmail('');
    setPhone('');
    setInterest('');
    setError('');
  };

  return (
    <div className="signup-container">
    <Navigation />
      <h2>Signup</h2>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            placeholder="Enter your name"
            value={name}
            onChange={handleNameChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            placeholder="Enter your email"
            value={email}
            onChange={handleEmailChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone:</label>
          <input
            type="tel"
            id="phone"
            placeholder="Enter your phone number"
            value={phone}
            onChange={handlePhoneChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="interest">I'm interested in SmartRep for:</label>
          <select
            id="interest"
            value={interest}
            onChange={handleInterestChange}
          >
            <option value="">Select an option</option>
            <option value="Patients">Patients</option>
            <option value="Providers">Providers</option>
            <option value="Employers">Employers</option>
            <option value="Insurers">Insurers</option>
          </select>
        </div>
        <button type="submit">Signup</button>
      </form>
    <Footer />
    </div>
  );
}

export default Signup;