// /components/Waitlist.js
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Navigation from './Navigation';
import Footer from './Footer';
import '../App.css';

function Waitlist() {
  console.log('Waitlist component rendered');
  const [emailOrPhone, setEmailOrPhone] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleInputChange = (event) => {
    setEmailOrPhone(event.target.value);
  };

  const handleSubmit = () => {
    const emailPhoneRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (emailPhoneRegex.test(emailOrPhone)) {
      // Valid email or phone
      setErrorMessage('');
      // Here you can implement your logic to handle the submission
      // For example, show a success message or trigger an API call
    } else {
      setErrorMessage('Please enter a valid email or phone number!');
    }
  };

  return (
    <div className="Waitlist">
      <Navigation />
      <p>We currently only cover Medicare Part B.</p>
      <p>Which insurance do you have?</p>
      <ul>
        <li>Medicaid</li>
        <li>Medicare Advantage</li>
        <li>
          Private Insurance: <TextField placeholder="Insurance Name" />
        </li>
      </ul>
      <p>Be the first to know when we support your insurance!</p>
      <TextField
        placeholder="Email or phone"
        value={emailOrPhone}
        onChange={handleInputChange}
      />
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Submit
      </Button>
      <p style={{ color: 'red' }}>{errorMessage}</p>
    <Footer />
    </div>
  );
}

export default Waitlist;