// src/components/Patients.js
import React from 'react';
import { Link } from 'react-router-dom';
import Navigation from './Navigation';
import Footer from './Footer';
import '../App.css';


function Patients() {
  return (
    <div className="patients-container">
    <Navigation />
      <h1>Patients</h1>
      <p>Your health comes first. SmartRep is your personal healthcare assistant, focused on preventive care.</p>
      <Link to="/signup">
        <button>Signup</button>
      </Link>
    <Footer />
    </div>
  );
}

export default Patients;