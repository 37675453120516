// /component/Providers.js
import React, { useState } from 'react';
import Navigation from './Navigation';
import Footer from './Footer';
import '../App.css';

function Providers() {
  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState(true);

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);

    // Email validation using regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValid(emailRegex.test(newEmail));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isValid) {
      // Handle form submission (e.g., send email, show confirmation)
      alert('Form submitted successfully');
    } else {
      alert('Please enter a valid email address');
    }
  };

  return (
    <div className="providers-container">
      <Navigation />
      <h1>Providers</h1>
      <p>Optimize your billing. Save time. Grow your practice.</p>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={handleEmailChange}
          className={!isValid ? 'invalid' : ''}
        />
        <button type="submit">Submit</button>
      </form>
    <Footer />
    </div>
  );
}

export default Providers;