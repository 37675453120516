import React from 'react';
import { Link } from 'react-router-dom';
import Navigation from './Navigation';
import Footer from './Footer';
import '../App.css';


function Insurers() {
  return (
    <div className="insurers-container">
    <Navigation />
      <h1>Insurers</h1>
      <p>Insurers partner with SmartRep to get proactive about patient care.</p>
      <Link to="/signup">
        <button>Signup</button>
      </Link>
    <Footer />
    </div>
  );
}

export default Insurers;