// src/components/Employers.js
import React from 'react';
import { Link } from 'react-router-dom';
import Navigation from './Navigation';
import Footer from './Footer';
import '../App.css';


function Employers() {
  return (
    <div>
    <Navigation />
      <h1>Employers</h1>
      <p>Employers partner with SmartRep to get proactive about employee healthcare. Healthier employees means a healthier business. Increase satisfaction, reduce sick days, lower premiums.</p>
      <Link to="/signup">
        <button>Signup</button>
      </Link>
      <br />
      <br />
    <Footer />
    </div>
  );
}

export default Employers;