// src/components/Login.js

import React, {useState} from 'react';
import Navigation from './Navigation';
import Footer from './Footer';
import '../App.css';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Basic validation (you can add more)
    if (!email || !password) {
      setError('Please fill in all fields.');
      return;
    }

    // You can add your login logic here, such as sending a request to an API

    // Clear form fields
    setEmail('');
    setPassword('');
    setError('');
  };

  return (
    <div className="login-container">
    <Navigation />
      <h2>Login</h2>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            placeholder="Enter your email"
            value={email}
            onChange={handleEmailChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            placeholder="Enter your password"
            value={password}
            onChange={handlePasswordChange}
          />
        </div>
        <button type="submit">Login</button>
      </form>
    <Footer />
    </div>
  );
}

export default Login;