// src /components/Checkout.js

import React, {useState} from 'react';
import { useLocation } from 'react-router-dom';
import Navigation from './Navigation';
import Footer from './Footer';
import '../App.css';

function Checkout() {
  const location = useLocation();
  const selectedServices = location.state ? location.state.selectedServices : [];
  const [hasPrimaryCareProvider, setHasPrimaryCareProvider] = useState(null);
  
  const [formData, setFormData] = useState({
    providerName: '',
    providerContact: '',
    yourName: '',
    yourContact: '',
    zipCode: '',
  });

  const handleProviderOptionChange = (option) => {
    setHasPrimaryCareProvider(option);
  };
  
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();
    // Add your logic here for handling the form submission
    console.log('Form submitted:', formData);
  };

  return (
    <div>
    <Navigation />
      <h1>Checkout</h1>
      <body className="Checkout-body">
      {selectedServices.length > 0 ? (
        <div>
          <p>Selected Services:</p>
          <ul>
            {selectedServices.map((service, index) => (
              <li key={index}>{service}</li>
            ))}
          </ul>
        </div>
      ) : (
        <p>No services selected.</p>
      )}
    <div>
        <p>Do you have a primary care provider?</p>
        <button
          onClick={() => handleProviderOptionChange('yes')}
          className={hasPrimaryCareProvider === 'yes' ? 'selected' : ''}
        >
          Yes
        </button>
        &nbsp;
        <button
          onClick={() => handleProviderOptionChange('no')}
          className={hasPrimaryCareProvider === 'no' ? 'selected' : ''}
        >
          No
        </button>
      </div>
      {hasPrimaryCareProvider === 'yes' && (
        <div>
          <p>Enter their information and we'll make it easy to schedule your next appointment:</p>
          <form onSubmit={handleSubmit}>
            <label>
              Provider name:&nbsp;
              <input
                type="text"
                name="providerName"
                value={formData.providerName}
                onChange={handleInputChange}
              />
            </label>
            <br />
            <label>
              Provider phone or email:&nbsp;
              <input
                type="text"
                name="providerContact"
                value={formData.providerContact}
                onChange={handleInputChange}
              />
            </label>
            <br />
            <label>
              Your Name:&nbsp;
              <input
                type="text"
                name="yourName"
                value={formData.yourName}
                onChange={handleInputChange}
              />
            </label>
            <br />
            <label>
              Your phone or email:&nbsp;
              <input
                type="text"
                name="yourContact"
                value={formData.yourContact}
                onChange={handleInputChange}
              />
            </label>
            <br />
            <button type="submit">Submit</button>
          </form>
        </div>
      )}
      {hasPrimaryCareProvider === 'no' && (
        <div>
          <p>Enter your information to help us recommend a provider:</p>
          <form onSubmit={handleSubmit}>
            <label>
              ZIP code:&nbsp;
              <input
                type="text"
                name="zipCode"
                value={formData.zipCode}
                onChange={handleInputChange}
              />
            </label>
            <br />
            <label>
              Your name:&nbsp;
              <input
                type="text"
                name="yourName"
                value={formData.yourName}
                onChange={handleInputChange}
              />
            </label>
            <br />
            <label>
              Your phone or email:&nbsp;
              <input
                type="text"
                name="yourContact"
                value={formData.yourContact}
                onChange={handleInputChange}
              />
            </label>
            <br />
            <button type="submit">Submit</button>
          </form>
        </div>
      )}
      </body>
    <Footer />
    </div>
  );
}

export default Checkout;